import { thunkWrapper } from '@/kytesoft-client/helpers';
import { loginThunk } from '@/kytesoft-client/store/app/thunks';
import { useGoogleLogin } from '@react-oauth/google';
import { useFormik } from 'formik';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { initialValues, loginSchema } from './constants';

export const useLogin = ({ onSuccess = () => null, onError = () => null, messages = {} } = {}) => {
  const dispatch = useDispatch();

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ access_token: accessToken }) => {
      await thunkWrapper({
        promise: dispatch(loginThunk({ accessToken, provider: 'google' })),
        thunkAction: loginThunk,
        onError,
        onSuccess,
      });
    },
    onError,
    flow: 'implicit',
  });

  const facebookLogin = () => {
    FB.login(
      function (response) {
        if (response.authResponse && response.status === 'connected') {
          console.log('Welcome!  Fetching your information.... ');
          const accessToken = get(response, 'authResponse.accessToken');
          dispatch(loginThunk({ accessToken, provider: 'facebook' }))
            .then(onSuccess)
            .catch(onError);
          return;
        }

        onError();
      },
      { scope: 'public_profile,email' },
    );
  };

  const loginForm = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema(messages),
    onSubmit: async (values, { setSubmitting }) => {
      await thunkWrapper({
        promise: dispatch(loginThunk(values)),
        thunkAction: loginThunk,
        onError,
        onSuccess,
      });

      setSubmitting(false);
    },
  });

  return { loginForm, googleLogin, facebookLogin };
};
