import { useAuth } from '@/kytesoft-client/auth';
import { useLogin } from '@/kytesoft-client/features/login';
import { useCart } from '@/kytesoft-client/features/useCart';
import { GroupInput } from '@components/compound/GroupInput';
import { Button } from '@components/primitive/Button';
import { KaImage } from '@components/primitive/Image';
import { Link } from '@components/primitive/Link';
import { ClickAwayListener, Popper } from '@mui/material';
import midMenuStyles from '@scss/layout/mid-menu.scss';
import { LIMIT } from '@utils/limit';
import { routes } from '@utils/routes';
import { includes, noop, size } from 'lodash';
import { useRouter } from 'next/router';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

interface IMidMenuProps {
  onClickMenu?: () => void;
  onClickCart?: () => void;
}

function MidMenu({ onClickMenu, onClickCart }: IMidMenuProps) {
  const router = useRouter();
  const { userInfo, isAuthenticated, loadingUserInfo } = useAuth();
  const { items } = useCart();
  const buttonEle = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const {
    loginForm: {
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      handleSubmit,
      isSubmitting,
      resetForm,
    },
  } = useLogin({
    messages: {
      username: {
        required: 'Tài khoản không được để trống',
      },
      password: {
        required: 'Mật khẩu không được để trống',
      },
    },
    onError: () => {
      toast.error('Đăng nhập thất bại');

      return null;
    },
    onSuccess: () => {
      toast.success('Đăng nhập thành công');
      setOpen(false);
      return null;
    },
  });

  const handleSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!searchValue) return;

    router.push({
      pathname: routes.PRODUCTS,
      query: {
        page: 1,
        limit: LIMIT.PRODUCTS_FILTER,
        q: searchValue,
      },
    });
  };

  useEffect(() => {
    setOpen(false);

    if (!router.query?.name) return;

    setSearchValue(String(router.query?.name));
  }, [router.asPath]);

  useEffect(() => {
    resetForm();
  }, [open]);

  return (
    <>
      <style jsx>{midMenuStyles}</style>
      {open && (
        <Popper
          className="ks-popper"
          placement="bottom-end"
          anchorEl={buttonEle.current}
          open={true}
        >
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <form
              noValidate
              autoComplete="off"
              className="ks-popper-auth"
              onSubmit={isSubmitting ? noop : handleSubmit}
            >
              <div className="content">
                <div className="form">
                  <div className="header">
                    <p className="title">Đăng nhập</p>
                    <Link title="Đăng ký" href={routes.AUTH} className="action">
                      Tạo tài khoản
                    </Link>
                  </div>
                  <div className="group">
                    <GroupInput
                      className="container -mb-10"
                      placeholder="Tài khoản"
                      label="Tài khoản hoặc Email"
                      fadePlaceholderShown
                      name="username"
                      value={values.username}
                      onChange={({ value }) => setFieldValue('username', value)}
                      error={errors.username}
                      touched={touched.username}
                      onBlur={() => setFieldTouched('username')}
                    />
                    <GroupInput
                      className="container -mb-10"
                      type="password"
                      placeholder="Mật khẩu"
                      label="Mật khẩu"
                      fadePlaceholderShown
                      name="password"
                      value={values.password}
                      onChange={({ value }) => setFieldValue('password', value)}
                      error={errors.password}
                      touched={touched.password}
                      onBlur={() => setFieldTouched('password')}
                    />
                    <Button type="submit" fullWidth isLoading={isSubmitting}>
                      ĐĂNG NHẬP
                    </Button>
                    <div className="forgot">
                      <Link className="link" href={routes.LOST_PASSWORD} title="forgot-password">
                        Quên mật khẩu?
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ClickAwayListener>
        </Popper>
      )}

      <div className="ks-mid-menu" id="mid-menu">
        <div className="ks-mid-menu-wrapper">
          <button onClick={onClickMenu} className="burger" title="burger">
            <span className="icon" />
            <span className="icon" />
            <span className="icon" />
          </button>

          <div className="brand">
            <Link href={routes.HOME} title="" className="link">
              <KaImage
                className="logo"
                src="/images/logo.png"
                alt="logo"
                objectFit="contain"
                useNextImage
                priority
                sizes="100%"
                width={160}
                height={90}
              />
            </Link>
          </div>

          <form onSubmit={handleSearch} className="search">
            <GroupInput
              onChange={({ value }) => setSearchValue(String(value))}
              type="text"
              placeholder="Bạn đang tìm sản phẩm nào?"
              className="container"
              fadePlaceholderShown
              value={searchValue}
              endAdornment={
                <Button type="submit" className="btn">
                  Tìm Kiếm
                </Button>
              }
            />
          </form>

          <div className="actions">
            <button
              disabled={loadingUserInfo}
              onClick={() => {
                if (isAuthenticated) return router.push(routes.MY_ACCOUNT);

                if (includes([routes.AUTH], router.pathname)) return;

                setOpen(true);
              }}
              ref={buttonEle}
              className="btn"
            >
              <span className="icon">
                <i className="fa-regular fa-user fa-lg" />
              </span>
              <span className="label">
                {isAuthenticated ? userInfo?.username : 'Đăng nhập / Đăng ký'}
              </span>
            </button>

            <button
              className="btn"
              title="wishlist"
              onClick={() =>
                router.push(isAuthenticated ? routes.MY_ACCOUNT_WISHLIST : routes.AUTH)
              }
              disabled={loadingUserInfo}
            >
              <span className="icon">
                <i className="fa-regular fa-heart fa-lg" />
              </span>
            </button>

            <button className="btn" title="cart" onClick={onClickCart}>
              <span className="icon">
                <i className="fa-light fa-bag-shopping fa-lg " />
              </span>
              <span className="label">({size(items)})</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MidMenu;
