import { CreateSocketClient, to } from '@/kytesoft-client/helpers';
import { setToken } from '@/kytesoft-client/request';
import appServices from '@/kytesoft-client/services/appServices';
import authServices from '@/kytesoft-client/services/authServices';
import contactServices from '@/kytesoft-client/services/contactServices';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, isEmpty } from 'lodash';
import {
  setAppSettings,
  setForgetPasswordCode,
  setForgetPasswordToken,
  setLoadingUserInfo,
  setRegisterToken,
  setSocketClient,
  setUserInfo,
} from './slice';

export const getAppSettingsThunk = createAsyncThunk(
  'app/getAppSettings',
  async (_, { dispatch }) => {
    const [err, resp] = await to(appServices.getAppSettings());
    if (isEmpty(err)) dispatch(setAppSettings(resp));
  },
);

export const getTokenThunk = createAsyncThunk('app/getToken', async ({ getMe }, { dispatch }) => {
  if (getMe) dispatch(setLoadingUserInfo(true));

  const [err, resp] = await to(authServices.clientGetToken());
  if (!isEmpty(err) || !resp.token) {
    if (getMe) dispatch(setLoadingUserInfo(false));
    return;
  }

  const token = resp?.token;
  setToken(token);

  if (getMe) {
    await dispatch(getMeThunk());
    dispatch(setLoadingUserInfo(false));
  }

  await dispatch(initialSocketClientsThunk({ token }));
});

export const getMeThunk = createAsyncThunk('app/getMe', async (_, { dispatch }) => {
  const [err, resp] = await to(authServices.clientGetMe());
  if (!isEmpty(err)) return;
  dispatch(setUserInfo(resp));
});

export const loginThunk = createAsyncThunk(
  'app/login',
  async (data, { rejectWithValue, dispatch }) => {
    const [err, _] = await to(authServices.clientLogin(data));
    if (!isEmpty(err)) {
      return rejectWithValue(err);
    }

    await dispatch(getTokenThunk({ getMe: true }));
  },
);

export const logoutThunk = createAsyncThunk(
  'app/logout',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const [err, __] = await to(authServices.clientLogout());
    if (!isEmpty(err)) {
      return rejectWithValue(err);
    }

    getState()?.app?.socketClient?.disconnect?.();

    setToken(null);
  },
);

export const registerThunk = createAsyncThunk(
  'app/register',
  async (data, { rejectWithValue, dispatch }) => {
    const [err, resp] = await to(authServices.clientRegister(data));
    if (!isEmpty(err)) {
      return rejectWithValue(err);
    }
    dispatch(setRegisterToken(resp?.token));
  },
);

export const registerResendOtpThunk = createAsyncThunk(
  'app/register/resendOtp',
  async (data, { rejectWithValue, dispatch }) => {
    const [err, resp] = await to(authServices.clientRegisterResendOtp(data));
    if (!isEmpty(err)) {
      return rejectWithValue(err);
    }
    dispatch(setRegisterToken(resp?.token));
  },
);

export const registerVerifyThunk = createAsyncThunk(
  'app/register/verify',
  async (data, { rejectWithValue, dispatch }) => {
    const [err, _] = await to(authServices.clientRegisterVerify(data));
    if (!isEmpty(err)) {
      return rejectWithValue(err);
    }
    dispatch(setRegisterToken(null));
  },
);

export const forgetPasswordThunk = createAsyncThunk(
  'app/forgetPassword',
  async (data, { rejectWithValue, dispatch }) => {
    const [err, resp] = await to(authServices.forgetPassword(data));
    if (!isEmpty(err)) {
      return rejectWithValue(err);
    }
    dispatch(setForgetPasswordToken(resp?.token));
  },
);

export const forgetPasswordResendOtpThunk = createAsyncThunk(
  'app/forgetPasswordResendOtp',
  async (data, { rejectWithValue, dispatch }) => {
    const [err, resp] = await to(authServices.forgetPasswordResendOtp(data));
    if (!isEmpty(err)) {
      return rejectWithValue(err);
    }
    dispatch(setForgetPasswordToken(resp?.token));
  },
);

export const forgetPasswordVerifyThunk = createAsyncThunk(
  'app/forgetPasswordVerify',
  async (data, { rejectWithValue, dispatch }) => {
    const [err, resp] = await to(authServices.forgetPasswordVerify(data));
    if (!isEmpty(err)) {
      return rejectWithValue(err);
    }
    dispatch(setForgetPasswordCode(data.code));
  },
);

export const resetPasswordThunk = createAsyncThunk(
  'app/resetPassword',
  async (data, { rejectWithValue, dispatch }) => {
    const [err, _] = await to(authServices.resetPassword(data));
    if (!isEmpty(err)) {
      return rejectWithValue(err);
    }

    dispatch(setForgetPasswordCode(null));
    dispatch(setForgetPasswordToken(null));
  },
);

export const createContactThunk = createAsyncThunk(
  'app/createContact',
  async (data, { rejectWithValue }) => {
    const [err, _] = await to(contactServices.createContact(data));
    if (!isEmpty(err)) {
      return rejectWithValue(err);
    }
    return true;
  },
);

export const subscribeThunk = createAsyncThunk(
  'app/subscribe',
  async (data, { rejectWithValue }) => {
    try {
      await appServices.subscribe(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const uploadFileThunk = createAsyncThunk(
  'app/uploadFile',
  async ({ isFile, file, usedFor, contentType }, { rejectWithValue }) => {
    const [err, generatedUrl] = await to(
      appServices.generateUploadUrl({ isFile, usedFor, fileName: get(file, 'name') }),
    );
    if (!isEmpty(err)) {
      return rejectWithValue(err);
    }
    const { url, key } = generatedUrl;
    const [uploadErr, _] = await to(appServices.upload({ file, contentType, url }));
    if (!isEmpty(uploadErr)) {
      return rejectWithValue(uploadErr);
    }

    return key;
  },
);

const initialSocketClientsThunk = createAsyncThunk(
  'app/initialSocketClientsThunk',
  async ({ token }, { dispatch }) => {
    const notifications = CreateSocketClient('notifications', { token });
    notifications.connect();

    notifications.on('error', (data) => {
      console.log('notifications error: ', data);
    });

    notifications.on('connected', (data) => {
      console.log('notifications connected: ', data);
    });

    notifications.on('joinedChannel', (data) => {
      console.log('joinedChannel: ', data);
    });

    notifications.on('notifications', (data) => {
      console.log('notifications listen', data);
    });

    notifications.on('disconnect', (data) => {
      console.log('notifications disconnect: ', data);
    });

    dispatch(setSocketClient(notifications));
  },
);
